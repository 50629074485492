import {
  useRoute,
  useRouter
} from 'vue-router'
import {
  getCurrentInstance,
  onMounted,
  ref
} from 'vue'
import {
  courierComissionUseCase
} from '@/domain/usecase'
import {
  encryptDecriptMethods
} from '@/plugins/encryptDecriptMethods'
import {
  useStore
} from 'vuex'
import CourierComissionForm from '@/views/master/courier/courier-comission/CourierComissionForm.vue'

export default {
  name: 'CourierComission',
  components: {
    CourierComissionForm
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const app = getCurrentInstance()
    const {
      $confirm,
      $toast,
      $strInd,
      $numbers
    } = app!.appContext.config.globalProperties
    const inputSearch = ref('')
    const sendInputSearch = ref('') as any
    const op = ref()
    const dataForm = ref(null)
    const totalRecords = ref(0)
    const sliderActive = ref(false)
    const dataSource = ref([]) as any
    const filters = {
      top: $numbers.totalItemPerPage,
      skip: 0,
    }

    const redirectAdd = () => {
      dataForm.value = null
      sliderActive.value = true
    }

    /** get data KomisiHd dari API */
    const getAllDataCourierComission = () => {
      store.dispatch('showLoading')
      courierComissionUseCase.getAll({
        expand: '&$expand=MsKota($select=Id,Nama),MsKomisiKurirJenisKendaraans($select=Id,MsJenisKendaraanId;$expand=MsJenisKendaraan($select=Nama,Kode))',
        select: '&$select=Id,MsKotaId,Target',
        search: sendInputSearch.val
      }).then((result) => {
        if (!result.error) {
          dataSource.value = result.result
        }
        store.dispatch('hideLoading')
      })
    }

    /** function search data */
    const searchData = (val: any) => {
      sendInputSearch.val = val ? val.toLowerCase() : val
      getAllDataCourierComission()
    }

    /** Fungsi emit dari perpindahan halaman */
    const onPage = (val: any) => {
      console.log('on page')
    }

    /** Push detail data dengan id */
    const detailData = (val: any) => {
      const dataEnc = encryptDecriptMethods.encrypt(val.Id)
      router.push({
        name: 'courier-comission-detail',
        params: {
          id: dataEnc
        }
      })
    }

    /** open overlay panel dengan params event dan data */
    const showDropDown = (evt: any, data: any) => {
      dataForm.value = data
      op.value.toggle(evt)
    }

    const closeSidebar = () => {
      sliderActive.value = false
    }

    const editData = (val: any) => {
      sliderActive.value = true
      dataForm.value = val
    }

    /** delete data komisi kurir dengan params Id KomisiKurirHd */
    const deleteData = (val: any) => {
      $confirm.require({
        header: 'Komisi Kurir',
        message: $strInd.confirm.msgDelete,
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          store.dispatch('showLoading')
          const response = await courierComissionUseCase.deleteData(val)

          if (response.error) {
            $toast.add({
              severity: 'error', detail: response.message, group: 'bc', life: 3000
            })
            store.dispatch('hideLoading')
          } else {
            $toast.add({
              severity: 'success', detail: response.result.detail ?? response.result.Detail, group: 'bc', life: 3000
            })
            getAllDataCourierComission()
          }
        }
      })
    }

    onMounted(() => {
      getAllDataCourierComission()
    })

    return {
      route,
      redirectAdd,
      inputSearch,
      searchData,
      totalRecords,
      filters,
      dataSource,
      onPage,
      detailData,
      op,
      showDropDown,
      dataForm,
      deleteData,
      editData,
      sliderActive,
      closeSidebar,
      getAllDataCourierComission
    }
  }
}
